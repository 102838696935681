/* ==========================================================================
Fresh Footer
========================================================================== */

footer.footer-dark {
  background: $blue-grey;
  color: $white;
  .columns {
    margin-top: 35px;
  }
  .footer-logo {
    img {
      height: 136px;
    }
  }
  .footer-column {
    .footer-header h3 {
      font-weight: 500;
      font-size: 1.2rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 20px;
    }
    ul.link-list {
      line-height: 40px;
      font-size: 1.1rem;
      a {
        color: $light-blue-grey;
        font-weight: 400;
        transition: all 0.5s;
      }
      :hover {
        color: $smoke-white;
      }
    }
    .level-item .icon {
      color: $secondary;
      transition: all 0.5s;
      :hover {
        color: $smoke-white;
      }
    }
  }
}
