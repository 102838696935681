/* ==========================================================================
Main SCSS file / Fresh
========================================================================== */

//Imports
@import 'partials/colors';
@import 'partials/navbar';
@import 'partials/dropdowns';
@import 'partials/sections';
@import 'partials/hero';
@import 'partials/footer';
@import 'partials/buttons';
@import 'partials/cards';
@import 'partials/forms';
@import 'partials/animations';
@import 'partials/responsive';
@import 'partials/utils';

.legal {
  h2 {
    font-size: 3.5em;
    line-height: 1.5;
  }
  h3 {
    font-size: 2em;
    line-height: 1.5;
  }
  h4 {
    font-size: 1.5em;
    line-height: 1.2;
  }
  p {
    margin-bottom: 1em;
  }
}
